<template>
  <form v-if="!preventBind" class="w-m-content financeiro-window"> <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52" v-if="!model.id">Novo Checklist Automático</p>
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52" v-else>Editar Checklist Automático</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <e-row mr>
        <e-col>
          <erp-s-field label="Checklist">
            <erp-input autofocus placeholder="Exemplo: Levantar dívidas do imóvel" v-model="model.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Descrição">
            <erp-input placeholder="Fazer levantamento de todas as dívidas e lançar..." v-model="model.descricao"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field label="Impeditivo?" icon-help="Se este checklist não for completado impede o bem de prosseguir para o evento de venda">
            <div class="m-l-xs"><sl-checkbox v-model="model.impeditivo" /></div>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Ativo">
            <div class="m-l-xs"><sl-checkbox v-model="model.active" /></div>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Posição">
            <erp-input type="number" v-model="model.order"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field label="Tipo(s) de Bem" icon-help="O checklist será salvo no bem baseado no tipo abaixo após seu registro no sistema">
            <tipos-bem-multiple-select v-model="model.tiposBem" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <evento-select v-model="model.evento" />
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field label="Seções" icon-help="O checklist será carregado em uma ou mais seções em específico">
            <secao-checklist-multiple-select v-model="model.secoes" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field label="Checklist Parente" icon-help="O checklist será relacionado à outro checklist">
            <config-checklist-input ref="parentSelect" v-model="model.parent" :finded-data-obj="cache.parentSelected" />
            <div class="m-t-xs font-12"><a @click="$refs.parentSelect.reset()">Remover parente</a></div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right"/>
      </div>
    </div>
  </form>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import {find, updateConfig, newConfig} from "@/domain/bem/services/configChecklist"
import SlCheckbox from "@/reuse/input/Checkbox"
import TiposBemMultipleSelect from "@/components/bem/components/include/TiposBemMultipleSelect"
import SecaoChecklistMultipleSelect from "@/components/bem/components/include/SecaoChecklistMultipleSelect";
import EventoSelect from "@/components/bem/components/include/EventoSelect"
import ConfigChecklistInput from "@/components/bem/components/include/ConfigChecklistInput"

const mockModel = {
  id: null,
  nome: null,
  descricao: null,
  impeditivo: false,
  order: 1000,
  active: true,
  tiposBem: [],
  evento: null,
  parent: null,
  secoes: []
}

export default {
  name: 'BemChecklistWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  components: {
    ConfigChecklistInput,
    EventoSelect,
    SecaoChecklistMultipleSelect,
    TiposBemMultipleSelect,
    SlCheckbox,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    // UTooltip,
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      loading: false,
      preventBind: true,
      model: this.getMock(),
      cache: {
        parentSelected: null
      }
    }
  },
  mounted() {
    if (this.isEditing) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {
    'model.parent' (v) {
      this.$nextTick(() => {
        console.log('Selecionou parente', v)
        if (this.model && this.model.id && v === this.model.id) {
          this.$uloc.dialog({
            title: 'Operação não permitida!',
            color: 'negative',
            message: `Você não pode relacionar uma configuração de checklist à ela mesma.`
          })
          this.$nextTick(() => {
            this.$refs.parentSelect.reset()
          })
        }
      })
    }
  },
  computed: {
    isEditing() {
      return this.id || (this.model && this.model.id)
    }
  },
  methods: {
    getMock() {
      let mock = JSON.parse(JSON.stringify(mockModel))
      return mock
    },
    load() {
      this.loading = true
      find(this.isEditing)
          .then(response => {
            console.log(response)
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))

      return data
    },
    prepareToEdit(data) {
      if (data.tiposBem && Array.isArray(data.tiposBem)) {
        data.tiposBem = data.tiposBem.map(tipo => {
          return tipo.id
        })
      }
      if (data.secoes && Array.isArray(data.secoes)) {
        data.secoes = data.secoes.map(tipo => {
          return tipo.id
        })
      }
      if (data.evento && data.evento.id) {
        data.evento = data.evento.id
      }
      if (data.parent && data.parent.id) {
        this.cache.parentSelected = data.parent
        data.parent = data.parent.id
      }
      return data
    },
    save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      let method = newConfig
      if (this.model.id) {
        method = updateConfig
      }
      method(data)
          .then(response => {
            this.loading = false
            console.log(response)
            const isEditing = this.isEditing
            this.model.id = response.data.id
            // this.$uloc.window.close(this.$root.wid)
            if (isEditing) {
              this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
              this.success = true
              if (type === 'andEdit') {
                this.dg()
              } else {
                this.$uloc.notify({
                  color: 'positive',
                  message: `Checklist Automático com ID #${response.data.id} alterado.`,
                  position: 'bottom-right',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
            } else {
              this.$uloc.window.emit(this.$root.wid, 'created', response.data)
              this.$uloc.notify({
                color: 'black',
                message: `Novo Checklist Automático com ID #${response.data.id} criado.`,
                position: 'bottom-right',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]*/
              })
            }
            this.$uloc.window.close(this.$root.wid)
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
