<template>
  <erp-select multiple @input="input" :value="model" placeholder="Nenhum" :options="tiposFiltered"
              :loading="loadingTipos" size="2">
    <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{ props.opt.label }}</span>
    </template>
  </erp-select>
</template>

<script>
import {ErpSelect} from "uloc-vue-plugin-erp"
import {list} from "@/domain/bem/services/checklistSecao"
import {treeCheck} from "@/components/cadastro/components/tiposbem/helpers/treeCheck"

export default {
  name: "SecaoChecklistMultipleSelect",
  props: ['value'],
  components: {
    ErpSelect
  },
  data() {
    return {
      model: this.value,
      loadingTipos: false,
      tipos: []
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    tiposFiltered() {
      if (!this.tipos || !this.tipos.length) {
        return []
      }
      return this.treeCheck(this.tipos)
    }
  },
  methods: {
    input (v) {
      this.model = v
      this.$emit('input', v)
    },
    load () {
      this.loadingTipos = true
      list(200, 1, null, true)
          .then(({data}) => {
            this.tipos = data.result
            console.log('Teste ', data)
            this.loadingTipos = false
            this.getId && this.load()
          })
          .catch(error => {
            console.error(error)
          })
    },
    treeCheck
  }
}
</script>
