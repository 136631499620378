import {list} from '@/domain/bem/services/configChecklist'

const search = function () {
  console.log('click window search bem...')
  let window = 'windowConfigChecklistSearch'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Buscar Configuração de Checklist',
    width: '800',
    height: '600',
    minHeight: '500',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden bg-white',
    props: {
      columns: [
        {label: 'ID', value: 'id'},
        {label: 'Nome', value: (v) => { return v.nome || '-'}},
        {label: 'Tipos Bem', value: (v) => { return v.tiposBem && v.tiposBem.length ? v.tiposBem.map(t => t.nome).join(', ') : '-'}},
        {label: 'Evento', value: (v) => { return v.evento ? v.evento.nome : '-'}},
        {label: 'Seções', value: (v) => { return v.secoes && v.secoes.length ? v.secoes.map(t => t.nome).join(', ') : '-'}}
      ],
      fetchData: (terms, filters, done) => {
        let rowsPerPage = 100
        list(rowsPerPage, filters.page, '&sortBy=id&descending=desc&search=' + terms)
          .then(({data}) => {
            /*let filter = function (terms, {field, list}) {
              const token = terms.toLowerCase()
              return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
            }

            let filteredData = filter(terms, {field: 'nome', list: data.result})*/
            let filteredData = data.result

            done(filteredData, {
              page: filters.page,
              rowsNumber: data.total, // response.totalRows,
              rowsPerPage: rowsPerPage
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, () => import('@/components/layout/window/WindowSearch.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        selected: (wid, val) => {
          console.log('Selecionado com sucesso: ', val)
          this.findedData = val
          this.model = val.id
          this.__emit(val.id)
          this.description = val.id + ' - ' + val.nome
          setTimeout(() => {
            this.$refs.input && this.$refs.input.focus()
          }, 200)
        }
      })
      console.log(wid)
    }) // return wid
}

export default search
